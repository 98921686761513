<template>
  <v-container fluid class="py-0 px-md-8  mb-0">
    <v-toolbar class="mt-n2 mx-auto" flat color="transparent">
      <div v-if="largeLayout" class="d-flex">
        <v-icon v-if="gameFamilyIcons[menu.name]" class="mr-2 gameFamilyIcon"
          >{{ gameFamilyIcons[menu.name] }}
        </v-icon>
        <!-- eslint-disable vue/no-v-html -->
        <i v-else class="mr-2 " v-html="updatedCustomIconSvg(menu.Image)"></i>
        <!--eslint-enable-->
        <span class="games-family-header">{{ menuHeader }}</span>
        <v-icon medium>mdi-chevron-right</v-icon>
      </div>
      <v-spacer v-if="largeLayout"></v-spacer>

      <v-select
        v-if="menu.name !== evoLiveDealer"
        class="mr-2"
        :value="subMenu || subMenuItemsFiltered[0]"
        :items="subMenuItemsFiltered"
        item-text="displayName"
        item-value="id"
        dense
        hide-details
        outlined
        flat
        color="grey"
        @change="goToSubMenu"
      >
        <template slot="item" slot-scope="data">
          <span class="cb-item ml-2">{{ data.item.displayName }}</span>
        </template>
      </v-select>
      <v-select
        v-if="menu.name !== evoLiveDealer"
        v-model="sortSelected"
        hide-details
        :items="gameSortOptions"
        item-value="id"
        dense
        outlined
        class="mr-2"
        color="grey"
        @change="goToMenu(-1)"
      >
        <template slot="selection" slot-scope="data">
          <span>{{ $str(data.item.displayName) }}</span
          ><v-icon v-if="data.item.icon !== ''">{{ data.item.icon }}</v-icon>
        </template>
        <template slot="item" slot-scope="data">
          <span>{{ $str(data.item.displayName) }}</span
          ><v-icon v-if="data.item.icon !== ''">{{ data.item.icon }}</v-icon>
        </template>

        >
      </v-select>
      <v-spacer v-if="smallLayout"></v-spacer>
      <v-btn-toggle
        v-model="selectedViewMode"
        dense
        class="align-center"
        rounded
        mandatory
      >
        <v-btn
          class="sort-btn"
          :value="GamesViewMode.Grid"
          color="body"
          small
          height="40px"
          ><v-icon small>$Grid</v-icon></v-btn
        >
        <v-btn
          class="sort-btn"
          :value="GamesViewMode.List"
          color="body"
          small
          height="40px"
        >
          <v-icon small>$List</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <!-- Grid Mode -->
    <v-container v-if="gamesViewMode === GamesViewMode.Grid" fluid class="pa-0">
      <!-- Hack for Evolution prototype -->
      <div v-if="replaceMenuWithEvolution" class="grid" :class="lobbyIconType">
        <EvolutionGameTile
          v-for="(table, index) in tablesList"
          :key="table.id + '-' + index"
          :table="table"
        />
      </div>
      <div v-else class="grid" :class="lobbyIconType">
        <GameTile
          v-for="(gameId, index) in displayedGames"
          :key="gameId + '-' + index"
          :game-id="gameId"
        />
      </div>
    </v-container>
    <!-- List Mode -->
    <v-container v-else class="pa-0 gameslist">
      <v-row v-if="largeLayout">
        <v-container class="pa-0">
          <v-row
            justify="center"
            align="center"
            class="text-center"
            style="font-size: 12px"
            no-gutters
          >
            <v-col class="text-left font-weight-bold" cols="4">
              {{ $str("GameNameLabel") }}</v-col
            ><v-col class="text-left font-weight-bold" cols="2">
              {{ $str("Lines") }} </v-col
            ><v-spacer></v-spacer>
            <v-col class="text-left font-weight-bold" cols="2">
              {{ $str("Jackpot") }}
            </v-col>

            <v-col v-if="loggedIn" class="text-left font-weight-bold" cols="1">
              {{ $str("Favorites") }}
            </v-col>
            <v-col class="text-left font-weight-bold" cols="2">
              {{ $str("Play") }}
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
        </v-container>
        <!-- HACK for evolution games prototype -->
        <v-container v-if="replaceMenuWithEvolution" class="scrollable pa-0">
          <EvolutionGameLargeRow
            v-for="(table, index) in tablesList"
            :key="table.id + '-' + index"
            :table="table"
          ></EvolutionGameLargeRow>
        </v-container>
        <v-container v-else class="scrollable pa-0">
          <GameLargeRow
            v-for="(gameId, index) in displayedGames"
            :key="gameId + '-' + index"
            :game-id="gameId"
          ></GameLargeRow>
        </v-container>
      </v-row>
      <v-row v-else-if="replaceMenuWithEvolution" class="ma-0">
        <EvolutionGameRow
          v-for="(table, index) in tablesList"
          :key="table.id + '-' + index"
          :table="table"
        ></EvolutionGameRow>
      </v-row>
      <v-row v-else class="ma-0">
        <GameRow
          v-for="(gameId, index) in displayedGames"
          :key="gameId + '-' + index"
          :game-id="gameId"
        ></GameRow>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import GameTile from "@/components/GameTile.vue";
import EvolutionGameTile from "@/components/EvolutionGameTile.vue";
import EvolutionGameRow from "@/components/EvolutionGameRow.vue";
import EvolutionGameLargeRow from "@/components/EvolutionGameLargeRow.vue";
import GameRow from "@/components/GameRow.vue";
import GameLargeRow from "@/components/GameLargeRow.vue";
import staggeredItems from "@/mixins/staggeredItems";
import { GamesViewMode } from "@/services/casino";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    GameTile,
    GameRow,
    GameLargeRow,
    EvolutionGameTile,
    EvolutionGameRow,
    EvolutionGameLargeRow
  },
  mixins: [staggeredItems],
  props: {
    menuId: { type: Number, required: true },
    subMenuId: { type: Number, required: false, default: null }
  },
  data() {
    return {
      staggeredItemsDelay: 0,
      sortSelected: {
        id: 1
      },
      GamesViewMode,
      evoLiveDealer: "Live Dealer EVO"
    };
  },
  computed: {
    ...mapGetters("games", [
      "menuGamesLists",
      "games",
      "gamesViewMode",
      "menus",
      "populatedGameFamilyMenus",
      "gameFamilyIcons",
      "gameSortOptions",
      "gameJackpots"
    ]),
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("externalLobbies", ["lobbies"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    ...mapGetters("evolutionGames", ["isEvolutionVisible", "tablesList"]),
    gamesList() {
      const gamesList = this.filterByVisible(this.menuGamesLists[this.menuId]);

      if (this.subMenu) {
        return gamesList.filter(gameId =>
          this.games[gameId].subMenuIds.includes(this.subMenu.uniqueId)
        );
      }

      return gamesList;
    },
    menu() {
      return this.menus[this.menuId];
    },
    subMenuItemsFiltered() {
      return this.menu?.subMenuItems.filter(
        subMenu => subMenu.games.length > 0
      );
    },
    subMenu() {
      return this.menu?.subMenuItems.find(
        subMenu => subMenu.id === this.subMenuId
      );
    },
    menuHeader() {
      return (
        this.menu.displayName +
        (this.subMenu ? ` - ${this.subMenu.displayName}` : "")
      );
    },
    numGamesToDisplay() {
      return Math.min(this.staggeredItemsCounter, this.gamesList.length);
    },
    displayedGames() {
      return this.sortByType(this.gamesList);
    },
    selectedViewMode: {
      get: function() {
        return this.gamesViewMode;
      },
      set: function(value) {
        this.setGamesViewMode(value);
      }
    },
    replaceMenuWithEvolution() {
      // display evolution specific components for this menu
      return this.menu.name === "Live Dealer EVO" && this.isEvolutionVisible;
    }
  },
  methods: {
    ...mapActions("games", ["setGamesViewMode"]),
    sortByType(list) {
      const ordertype = this.gameSortOptions.find(
        x =>
          x.id ===
          (this.sortSelected?.id ? this.sortSelected?.id : this.sortSelected)
      );
      return list.sort((a, b) => {
        const [firstValue, secondValue] =
          ordertype.attribute === "jackpot"
            ? [this.gameJackpots[a] || 0, this.gameJackpots[b] || 0]
            : [
                this.games[a][ordertype.attribute],
                this.games[b][ordertype.attribute]
              ];
        if (ordertype.direction === "DESC") {
          return firstValue < secondValue ? -1 : 1;
        } else {
          return firstValue > secondValue ? -1 : 1;
        }
      });
    },
    goTo(menuId, subMenuId = null) {
      if (
        this.$route.name === "games" &&
        this.$route.params.menuId === menuId &&
        this.$route.params.subMenuId === subMenuId
      ) {
        return;
      }

      this.$router.push({
        name: "games",
        params: { menuId, subMenuId }
      });
    },
    goToMenu(menuId) {
      if (menuId === -1) {
        this.goTo(this.menuId);
      } else {
        this.goTo(menuId);
      }
    },
    goToSubMenu(subMenuId) {
      this.goTo(this.menuId, subMenuId);
    },
    filterByVisible(gamesList) {
      return gamesList.filter(
        gameId => this.lobbies[gameId]?.isVisible ?? true
      );
    },
    updatedCustomIconSvg(svgCode) {
      // if custom SVG has incompatible built in sizing, update it to the correct icon size
      const width = 20;
      const height = 20;

      const parser = new DOMParser();
      const doc = parser.parseFromString(svgCode, "image/svg+xml");

      const svgElement = doc.querySelector("svg");

      if (svgElement) {
        svgElement.setAttribute("width", width);
        svgElement.setAttribute("height", height);
      }

      //Check for inline style and update 'width' and 'height' properties
      let styleAttribute = svgElement?.getAttribute("style");
      if (styleAttribute) {
        styleAttribute = styleAttribute.replace(
          /width\s*:\s*[^;]+/i,
          `width:${width}px`
        );
        styleAttribute = styleAttribute.replace(
          /height\s*:\s*[^;]+/i,
          `height:${height}px`
        );
        svgElement.setAttribute("style", styleAttribute);
      }

      const serializer = new XMLSerializer();
      return serializer.serializeToString(doc);
    }
  }
};
</script>
<style scoped lang="scss">
.games-family-header {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.gameFamilyIcon {
  width: 20px !important;
  height: 20px !important;
}

.gameFamilyIcon svg {
  width: 20px !important;
  height: 20px !important;
}

.v-input {
  width: 120px;
  flex-grow: 0;
}

.sort-btn.v-btn {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.grid {
  margin: 0 auto;
  display: grid;
  gap: 10px;
  padding-top: 10px;
}
@media (max-width: 650px) {
  .grid.tall {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  }
}
@media (min-width: 651px) {
  .grid.tall {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}
@media (max-width: 650px) {
  .grid.square {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
}
@media (min-width: 651px) {
  .grid.square {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

::v-deep
  div.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--dark {
  color: white !important;
  caret-color: white !important;
}
::v-deep
  div.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--light {
  color: black !important;
  caret-color: black !important;
}
.menuIcon {
  width: 20px !important;
  height: 20px !important;
}
::v-deep .v-toolbar__content {
  padding: 0;
}
::v-deep .theme--dark.v-input.v-input--is-focused,
::v-deep .theme--dark.v-labelt.v-input--is-focused {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff !important;
}
::v-deep .theme--light.v-input.v-input--is-focused,
::v-deep .theme--light.v-label.v-input--is-focused {
  color: rgba(0, 0, 0, 0.87) !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  caret-color: rgba(0, 0, 0, 0.87) !important;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 40px;
  min-width: 40px;
}
</style>

<template>
  <v-container width="100vw" class="pa-0">
    <v-card
      flat
      class="d-flex align-center"
      color="transparent"
      @click="onTableClicked"
    >
      <v-card-title class="pa-0 ">
        <v-img
          class="rounded"
          :src="getTableIcon(table)"
          :lazy-src="lazyLoadingImage"
          width="106.666px"
          contain
          height="100px"
        >
        </v-img>
      </v-card-title>
      <v-card-actions class="pa-0 mr-2">
        <p
          class="gameName ma-0"
          style="padding-left: 10px;"
          v-text="table.name"
        ></p>
      </v-card-actions>
      <v-spacer></v-spacer>
    </v-card>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import { toggleFavorite } from "@/mixins/toggleFavorite";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [toggleFavorite],
  props: {
    table: { type: Object, required: true }
  },
  computed: {
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  methods: {
    ...mapActions("evolutionGames", ["launchTable"]),
    getTableIcon() {
      try {
        return require(`@/assets/evolution/${this.table.gameTypeUnified}.jpg`);
      } catch (e) {
        return this.lazyLoadingImage;
      }
    },
    onTableClicked() {
      this.launchTable({ table: this.table });
    }
  }
};
</script>
<style scoped lang="scss">
.favorite {
  margin-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: normal;
  color: white;
  cursor: pointer;

  &.active {
    color: #ffd600;
  }
}

::v-deep .v-ripple__container {
  display: none !important;
}
.gameName {
  text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gameJackpot {
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 140% */
  letter-spacing: 0.4px;
}
</style>

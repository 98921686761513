<template>
  <v-container class="pa-0">
    <v-card flat color="transparent" class="">
      <v-row
        class="text-center ml-0"
        justify="space-between"
        align="center"
        style="font-size: 12px;"
      >
        <v-col class="text-left px-0 pt-0 pb-0" align="baseline" :cols="4">
          <v-row class="ma-0 flex-nowrap" align="center">
            <v-card-title class="pa-0 ">
              <v-img
                class="rounded"
                :src="getTableIcon(table)"
                :lazy-src="lazyLoadingImage"
                width="106.666px"
                height="80px"
                contain
                @click="onTableClicked"
              >
              </v-img>
            </v-card-title>

            <p class="font-weight-bold mb-0 mx-4" v-text="table.name"></p>
          </v-row>
        </v-col>
        <v-col cols="2" class="pa-0"> </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" class="pa-0"> </v-col>

        <v-col cols="2" class="pa-0 mr-2">
          <v-btn
            class="align-self-center"
            color="button buttonTxt--text pa-0"
            width="137.333px"
            height="40px"
            style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 40px;"
            @click="onTableClicked"
            ><v-icon small class="mr-2">$Play</v-icon>{{ $str("Play") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import { toggleFavorite } from "@/mixins/toggleFavorite";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [toggleFavorite],
  props: {
    table: { type: Object, required: true }
  },
  computed: {
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  methods: {
    ...mapActions("evolutionGames", ["launchTable"]),
    getTableIcon() {
      try {
        return require(`@/assets/evolution/${this.table.gameTypeUnified}.jpg`);
      } catch (e) {
        return this.lazyLoadingImage;
      }
    },
    onTableClicked() {
      this.launchTable({ table: this.table });
    }
  }
};
</script>

<style scoped lang="scss">
.favorite {
  margin-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: normal;
  color: white;
  cursor: pointer;

  &.active {
    color: #ffd600;
  }
}
</style>

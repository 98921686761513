<template>
  <v-container class="pa-0">
    <v-card flat color="transparent" class="" :disabled="isExcluded">
      <v-row
        class="text-center ml-0"
        justify="space-between"
        align="center"
        style="font-size: 12px;"
      >
        <v-col class="text-left px-0 pt-0 pb-0" align="baseline" :cols="4">
          <v-row class="ma-0 flex-nowrap" align="center">
            <v-card-title class="pa-0 ">
              <v-img
                class="rounded"
                :src="gameIcon(game)"
                :lazy-src="lazyLoadingImage"
                :width="isSquareIcon ? '106.666px' : '70px'"
                :contain="!gameIcon(game)"
                height="80px"
                @click="onGameClicked"
              >
                <v-overlay v-if="isExcluded" absolute>
                  <v-icon large>mdi-lock</v-icon>
                </v-overlay>
              </v-img>
            </v-card-title>

            <p class="font-weight-bold mb-0 mx-4" v-text="gameName"></p>
          </v-row>
        </v-col>
        <v-col cols="2" class="pa-0">
          <p
            v-if="lines"
            class="game-jackpot text-left font-weight-bold ma-0"
            v-text="lines"
          ></p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" class="pa-0">
          <p
            v-if="jackpot"
            class="text-left game-jackpot font-weight-bold  ma-0"
            v-text="$cur(jackpot)"
          ></p>
        </v-col>

        <v-col
          v-if="showFavoriteStar(game)"
          cols="1"
          class="pa-0 d-flex  justify-start"
        >
          <i
            class="fi fi-sr-star favorite"
            :class="{
              active: isFavorite,
              starOff: !isFavorite
            }"
            @click.stop="toggleFavorite"
          >
          </i>
        </v-col>
        <v-col cols="2" class="pa-0 mr-2">
          <v-btn
            class="align-self-center"
            color="button buttonTxt--text pa-0"
            width="137.333px"
            height="40px"
            style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 40px;"
            @click="onGameClicked"
            ><v-icon small class="mr-2">$Play</v-icon>{{ $str("Play") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import { toggleFavorite } from "@/mixins/toggleFavorite";
import { mapGetters } from "vuex";

export default {
  mixins: [toggleFavorite],
  props: {
    gameId: { type: String, required: true }
  },
  computed: {
    ...mapGetters("games", [
      "games",
      "gameIsExcluded",
      "gameJackpots",
      "showFavoriteStar",
      "showDetailsButton",
      "gameIcon"
    ]),
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    game() {
      return this.games[this.gameId];
    },
    isExcluded() {
      return this.gameIsExcluded[this.gameId];
    },
    jackpot() {
      return this.gameJackpots[this.gameId];
    },
    gameName() {
      return this.game?.name;
    },
    lines() {
      return this.game?.lines;
    },
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  methods: {
    launchGame() {
      this.$store.dispatch("games/launchGame", {
        gameId: this.gameId,
        externalLobbyId: this.game.externalLobbyId
      });
    },
    showGameDetailDialog() {
      this.$store.dispatch("dialogs/showGameDetail", { id: this.gameId });
    },
    onGameClicked() {
      if (this.game?.isExternalGame) {
        this.launchGame();
      } else {
        this.showGameDetailDialog();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.favorite {
  margin-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: normal;
  color: white;
  cursor: pointer;

  &.active {
    color: #ffd600;
  }
}
</style>
